const InstagramIcon = () => (
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1908 33.7132C26.4956 33.7132 34.0387 26.1701 34.0387 16.8653C34.0387 7.56042 26.4956 0.017334 17.1908 0.017334C7.88591 0.017334 0.342834 7.56042 0.342834 16.8653C0.342834 26.1701 7.88591 33.7132 17.1908 33.7132Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3111 8.32257C11.3406 8.32257 10.4098 8.70801 9.72343 9.39413C9.03705 10.0803 8.65125 11.0109 8.65088 11.9814V21.742C8.65088 22.7128 9.03651 23.6438 9.72294 24.3302C10.4094 25.0166 11.3404 25.4023 12.3111 25.4023H22.0717C23.0422 25.4019 23.9729 25.0161 24.659 24.3297C25.3451 23.6433 25.7306 22.7125 25.7306 21.742V11.9814C25.7302 11.0111 25.3446 10.0807 24.6585 9.39463C23.9724 8.70855 23.042 8.32294 22.0717 8.32257H12.3111ZM23.1245 11.987C23.1245 12.2666 23.0135 12.5348 22.8158 12.7325C22.6181 12.9302 22.3499 13.0412 22.0703 13.0412C21.7907 13.0412 21.5226 12.9302 21.3249 12.7325C21.1272 12.5348 21.0161 12.2666 21.0161 11.987C21.0161 11.7074 21.1272 11.4393 21.3249 11.2416C21.5226 11.0439 21.7907 10.9328 22.0703 10.9328C22.3499 10.9328 22.6181 11.0439 22.8158 11.2416C23.0135 11.4393 23.1245 11.7074 23.1245 11.987ZM17.1928 13.938C16.4174 13.938 15.6738 14.2461 15.1255 14.7944C14.5772 15.3426 14.2691 16.0863 14.2691 16.8617C14.2691 17.6371 14.5772 18.3808 15.1255 18.9291C15.6738 19.4774 16.4174 19.7854 17.1928 19.7854C17.9682 19.7854 18.7119 19.4774 19.2602 18.9291C19.8085 18.3808 20.1165 17.6371 20.1165 16.8617C20.1165 16.0863 19.8085 15.3426 19.2602 14.7944C18.7119 14.2461 17.9682 13.938 17.1928 13.938ZM12.8621 16.8617C12.8621 15.7135 13.3182 14.6123 14.1301 13.8004C14.942 12.9885 16.0432 12.5324 17.1914 12.5324C18.3396 12.5324 19.4408 12.9885 20.2527 13.8004C21.0646 14.6123 21.5207 15.7135 21.5207 16.8617C21.5207 18.0099 21.0646 19.1111 20.2527 19.923C19.4408 20.7349 18.3396 21.191 17.1914 21.191C16.0432 21.191 14.942 20.7349 14.1301 19.923C13.3182 19.1111 12.8621 18.0099 12.8621 16.8617Z"
      fill="black"
    />
  </svg>
)
export default InstagramIcon
