const LinktreeIcon = () => (
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5479 33.7132C26.8528 33.7132 34.3958 26.1701 34.3958 16.8653C34.3958 7.56042 26.8528 0.017334 17.5479 0.017334C8.24303 0.017334 0.699951 7.56042 0.699951 16.8653C0.699951 26.1701 8.24303 33.7132 17.5479 33.7132Z"
      fill="white"
    />
    <path
      d="M24.9482 12.3747H10.1476V14.3738H24.9482V12.3747ZM10.1476 16.1848V25.4796L17.5479 21.329L24.9482 25.4796V16.1848H10.1476ZM24.9482 8.56461H10.1476V10.5627H24.9482V8.56461Z"
      fill="black"
    />
  </svg>
)
export default LinktreeIcon
