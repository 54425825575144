const MIcon = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9765 33.7132C26.2813 33.7132 33.8244 26.1701 33.8244 16.8653C33.8244 7.56042 26.2813 0.017334 16.9765 0.017334C7.67162 0.017334 0.12854 7.56042 0.12854 16.8653C0.12854 26.1701 7.67162 33.7132 16.9765 33.7132Z"
      fill="white"
    />
    <path
      d="M10.6769 13.2138C10.6871 13.1123 10.6734 13.0099 10.637 12.9147C10.6005 12.8195 10.5423 12.7341 10.4669 12.6654L8.9102 10.7762V10.4937H13.742L17.477 18.748L20.7604 10.4937H25.3672V10.7762L24.0363 12.0604C23.9799 12.1039 23.9364 12.162 23.9104 12.2283C23.8845 12.2947 23.877 12.3668 23.8888 12.4371V21.8823C23.877 21.9525 23.8845 22.0247 23.9104 22.091C23.9364 22.1574 23.9799 22.2154 24.0363 22.2589L25.3363 23.544V23.8273H18.7996V23.5448L20.1462 22.2281C20.2788 22.0948 20.2788 22.0556 20.2788 21.8523V14.2171L16.5353 23.7956H16.0303L11.6719 14.2171V20.6372C11.6361 20.9064 11.7253 21.1789 11.9136 21.3739L13.6645 23.514V23.7956H8.7002V23.514L10.4502 21.3739C10.5428 21.2773 10.6115 21.1604 10.6509 21.0325C10.6903 20.9045 10.6992 20.7692 10.6769 20.6372V13.2138Z"
      fill="black"
    />
  </svg>
)
export default MIcon
