const TIcon = () => (
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7622 33.8866C27.067 33.8866 34.6101 26.3435 34.6101 17.0387C34.6101 7.73382 27.067 0.190735 17.7622 0.190735C8.45732 0.190735 0.914246 7.73382 0.914246 17.0387C0.914246 26.3435 8.45732 33.8866 17.7622 33.8866Z"
      fill="white"
    />
    <mask
      id="mask0_66_2960"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="19"
      height="19"
    >
      <path d="M8.58875 8.15897H26.9356V26.5058H8.58875V8.15897Z" fill="white" />
    </mask>
    <g mask="url(#mask0_66_2960)">
      <path
        d="M23.033 25.3721C22.423 26.0349 21.0103 26.4852 19.7451 26.5058H19.606C15.3579 26.5058 14.4337 23.3226 14.4337 21.465V16.3065H12.7611C12.7149 16.3064 12.6692 16.2971 12.6267 16.2791C12.5841 16.2611 12.5456 16.2348 12.5134 16.2018C12.4476 16.1343 12.4108 16.0437 12.4109 15.9495V13.5124C12.411 13.3874 12.4488 13.2654 12.5195 13.1623C12.59 13.0604 12.6905 12.9829 12.8069 12.9406C14.9894 12.1563 15.6721 10.2176 15.7654 8.74378C15.7921 8.34932 15.9924 8.15898 16.3295 8.15898H18.8323C18.8791 8.15803 18.9256 8.16662 18.969 8.18424C19.0124 8.20185 19.0517 8.22811 19.0846 8.26141C19.118 8.29483 19.1445 8.33453 19.1624 8.37822C19.1804 8.42191 19.1896 8.46873 19.1893 8.51597V12.6463H22.1118C22.2043 12.6463 22.2938 12.6845 22.3595 12.751C22.4253 12.8185 22.4621 12.9091 22.462 13.0033V15.9311C22.4621 16.0254 22.4253 16.1159 22.3595 16.1834C22.3273 16.2166 22.2889 16.243 22.2463 16.2612C22.2038 16.2793 22.1581 16.2887 22.1118 16.2889H19.1763V21.0629C19.1763 22.2616 19.9523 22.5926 20.43 22.5926C20.8203 22.5754 21.2049 22.4925 21.5675 22.3472C21.7067 22.2726 21.866 22.2442 22.0224 22.2662C22.0875 22.2849 22.1462 22.3212 22.192 22.3711C22.2379 22.421 22.2691 22.4825 22.2823 22.549L23.0559 24.8531C23.1079 25.0342 23.163 25.233 23.033 25.3721Z"
        fill="black"
      />
    </g>
  </svg>
)
export default TIcon
