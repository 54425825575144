/* eslint-disable lodash/import-scope */
import { BigNumber as etherBigNumber } from '@ethersproject/bignumber'
import { Box, EmptyIcon, Flex, Text, useModal, useToast } from '@pancakeswap/uikit'
import ModalTransferMultiChain from 'components/Modal/ModalTransferMultiChain'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import useTokenBalance from 'hooks/useTokenBalance'
import { useEffect, useState } from 'react'
// import { useAllBlockchain } from 'state/home/fetchAllBlockChain'
import { useCurrencyByChain, useFetchAllCurrencyByChain } from 'state/home/fetchCurrency'

import { useBridgeContract } from 'hooks/useContract'
import { useRouter } from 'next/dist/client/router'
import { useFetchTransaction } from 'state/home/fetchTransaction'
import { formatBigNumber } from 'utils/formatBalance'
// import {useWeb3React} from '../../../packages/wagmi/src/useWeb3React'
// import TransactionBridge from './components/TransactionBridge'
import { ChainId } from '@pancakeswap/sdk'
import { useCurrency } from 'hooks/Tokens'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import useCatchTxError from 'hooks/useCatchTxError'
import { useAllBlockchain } from 'state/home/fetchAllBlockChain'
import { useBalance } from 'wagmi'
// eslint-disable-next-line lodash/import-scope
import { avalandche, bsc, creditChain } from '@pancakeswap/wagmi/chains'
import _, { repeat } from 'lodash'
import Image from 'next/image'
import TelegramIcon from 'components/Menu/TelegramIcon'
import FacebookIcon from 'components/Menu/FacebookIcon'
import DiscordIcon from 'components/Menu/DiscordIcon'
import InstagramIcon from 'components/Menu/InstagramIcon'
import MIcon from 'components/Menu/MIcon'
import TIcon from 'components/Menu/TIcon'
import LinktreeIcon from 'components/Menu/LinktreeIcon'
import RedditIcon from 'components/Menu/RedditIcon'
import styled from 'styled-components'
import * as Styles from './styles'
import GatewayToGet from './components/GatewayToGet'

// const NetworkSelect = ({ switchNetwork, chainId, blockchainList }) => {
//   return (
//     <Styles.NetworkSelectContentStyle>
//       {blockchainList?.map((chain) => {
//         return (
//           <Box
//             className="network-item"
//             key={chain.chainid}
//             style={{
//               justifyContent: 'flex-start',
//               opacity: chain.chainid !== chainId ? 1 : 0.5,
//               cursor: chain.chainid !== chainId ? 'pointer' : 'not-allowed',
//             }}
//             onClick={() => chain.chainid !== chainId && switchNetwork(chain)}
//           >
//             <ChainLogo chainId={chain.chainid} />
//             <Text
//               color={chain.chainid === chainId ? 'secondary' : 'text'}
//               bold={chain.chainid === chainId}
//               pl="12px"
//               fontSize={[12, , 14]}
//             >
//               {chain.title}
//             </Text>
//           </Box>
//         )
//       })}
//     </Styles.NetworkSelectContentStyle>
//   )
// }

const FooterContainer = styled.div`
  position: absolute;
  bottom: 40px;
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-content: center;
  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
`

const CurrencySelect = ({ fromNetwork, switchCurrency, currencySelect, currencyListByChain }) => {
  // let currency
  // // console.log('currencyListByChain', currencyListByChain)
  // if (currencyListByChain?.length > 0 && typeof fromNetwork !== 'undefined') {
  //   currency = currencyListByChain[0]
  // }

  return (
    // <Styles.CurrenciesSelectContentStyle>
    //   {typeof currency !== 'undefined' ? (
    //     <Box
    //       className="curr-item"
    //       key={currency._id}
    //       style={{
    //         justifyContent: 'flex-start',
    //         opacity: currency._id !== currencySelect?._id ? 1 : 0.5,
    //         cursor: currency._id !== currencySelect?._id ? 'pointer' : 'not-allowed',
    //       }}
    //       onClick={() => currency._id !== currencySelect?._id && switchCurrency(currency)}
    //     >
    //       <img src={`/images/currencies/${currency?.code}.png`} alt="logo" />
    //       <Text
    //         color={currency._id === currencySelect?._id ? 'secondary' : 'text'}
    //         bold={currency._id === currencySelect?._id}
    //         pl="12px"
    //       >
    //         {currency.title} ({currency.code})
    //       </Text>
    //     </Box>
    //   ) : (
    //     <Flex alignItems="center">
    //       <EmptyIcon width={24} />
    //       <Text as="p" ml="16px" fontSize={[12, 14]} fontWeight={500}>
    //         There Are No Data
    //       </Text>
    //     </Flex>
    //   )}
    // </Styles.CurrenciesSelectContentStyle>
    <Styles.CurrenciesSelectContentStyle>
      {currencyListByChain?.length > 0 ? (
        currencyListByChain?.map((item) => {
          const currency = item
          // const currency = allCurrency.find((cur) => {
          //   if (cur._id === item._id) {
          //     return {
          //       ...item,
          //       ...cur,
          //     }
          //   }
          //   return {}
          // })

          return (
            <Box
              className="curr-item"
              key={currency._id}
              style={{
                justifyContent: 'flex-start',
                opacity: currency._id !== currencySelect?._id ? 1 : 0.5,
                cursor: currency._id !== currencySelect?._id ? 'pointer' : 'not-allowed',
              }}
              onClick={() => currency._id !== currencySelect?._id && switchCurrency(currency)}
            >
              <img src={`/images/currencies/${currency?.code}.png`} alt="logo" />
              <Text
                color={currency._id === currencySelect?._id ? 'secondary' : 'text'}
                bold={currency._id === currencySelect?._id}
                pl="12px"
              >
                {currency.title} ({currency.code})
              </Text>
            </Box>
          )
        })
      ) : (
        <Flex alignItems="center">
          <EmptyIcon width={24} />
          <Text as="p" ml="16px" fontSize={[12, 14]} fontWeight={500}>
            There Are No Data
          </Text>
        </Flex>
      )}
    </Styles.CurrenciesSelectContentStyle>
  )
}

const Styled04 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding-top: 200px;
  margin-left: 300px;
  @media (min-width: 768px) {
    padding-top: 300px;
  }
  @media (max-width: 500px) {
    width: 170px;
    padding-top: 275px;
    margin-left: 250px;
  }
`

const Styled03 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-top: 95px;
  min-width: 375px;
  min-height: 586px;
`

const Home = ({ pageSupportedChains }: { pageSupportedChains: number[] }) => {
  // const account = useWeb3React()
  const { account, chainId, isConnected } = useActiveWeb3React()
  const native = useNativeCurrency()
  const router = useRouter()
  const [isShowPopup, setShowPopup] = useState(null)
  const { toastError } = useToast()
  const { fetchWithCatchTxError, loading: isConfirming } = useCatchTxError()
  const { callWithGasPrice } = useCallWithGasPrice()
  const isBSC = chainId === ChainId.BSC
  // const bnbBalance = useBalance({ addressOrName: account, chainId: ChainId.BSC })
  const nativeBalance = useBalance({ addressOrName: account, enabled: !isBSC })

  const { pendingChainId, isLoading, canSwitch, switchNetworkAsync } = useSwitchNetwork()

  const [formValue, setFormValue] = useState({
    fromNetwork: undefined,
    toNetwork: undefined,
    currency: undefined,
    address: undefined,
    sendAmount: undefined,
    receiveAmount: undefined,
  })
  const [formError, setFormError] = useState<any>({
    fromNetwork: '',
    toNetwork: '',
    currency: '',
    address: '',
    sendAmount: '',
    receiveAmount: '',
    contract: '',
  })
  useEffect(() => {
    return () => {
      setFormValue({
        fromNetwork: undefined,
        toNetwork: undefined,
        currency: undefined,
        address: undefined,
        sendAmount: undefined,
        receiveAmount: undefined,
      })
      setFormError({
        fromNetwork: '',
        toNetwork: '',
        currency: '',
        address: '',
        sendAmount: '',
        receiveAmount: '',
        contract: '',
      })
    }
  }, [])

  const [toChainList, setToChainList] = useState([])
  const [minTokenAmount, setMinTokenAmount] = useState(0)

  const _currency = useCurrency(formValue?.currency?.token_address)

  const [gasFee, setGasFee] = useState(null)
  const bridgeContract = useBridgeContract(formValue?.currency?.contract_bridge)
  const { balance: getBalance } = useTokenBalance(formValue?.currency?.token_address)

  let currencyBalance = 0

  if (formValue?.currency?.token_address === '0x0000000000000000000000000000000000000000') {
    if (typeof nativeBalance.data !== 'undefined') {
      currencyBalance = +formatBigNumber(nativeBalance.data.value, 6, _currency?.decimals || 18)
    }
  } else {
    currencyBalance = +formatBigNumber(etherBigNumber.from(getBalance.toString()), 6, _currency?.decimals || 18)
  }

  const formIsValid =
    !!formError?.fromNetwork ||
    !!formError?.toNetwork ||
    !!formError?.currency ||
    !!formError?.address ||
    !!formError?.sendAmount ||
    !!formError?.receiveAmount

  const allBlockchain = useAllBlockchain()
  const { setParamsTransaction } = useFetchTransaction()
  const { setFetchCurrencyAttrParams } = useFetchAllCurrencyByChain({
    blockchain_id: formValue.fromNetwork?._id || allBlockchain?.find((item) => item.chainid === chainId)?._id,
  })

  const currencyByChain = useCurrencyByChain()
  const getToChain = async () => {
    const listToBlockchain = await bridgeContract.listBlockchainTo()
    const supportedToChain = _.intersection(
      [bsc, creditChain, avalandche].map((item) => item.network),
      listToBlockchain,
    )
    setToChainList(supportedToChain)
  }

  useEffect(() => {
    if (formValue?.currency?.contract_bridge) {
      getToChain()
    }
  }, [formValue?.currency?.contract_bridge])
  // Fetch transaction
  useEffect(() => {
    setParamsTransaction((prev) => ({
      ...prev,
      pageSize: 3,
    }))
  }, [])

  useEffect(() => {
    if (allBlockchain) {
      setFormValue((prev) => ({
        ...prev,
        fromNetwork: allBlockchain?.find((item) => item?.chainId === chainId),
      }))
    }
  }, [chainId, allBlockchain])
  const currentFromChain = allBlockchain?.find((item) => item?.chainId === chainId)
  // Fetch currency attr
  useEffect(() => {
    if (formValue.fromNetwork?._id) {
      setFetchCurrencyAttrParams({
        blockchain_id: formValue.fromNetwork?._id || currentFromChain._id,
      })
    }
  }, [formValue.fromNetwork, currentFromChain, allBlockchain])

  // Auto set address when logged in
  useEffect(() => {
    setFormValue({
      ...formValue,
      address: account || '',
    })
    setFormError({
      ...formError,
      address: '',
    })
    setParamsTransaction((prev) => ({
      ...prev,
      pageSize: 3,
    }))
  }, [account])

  // Auto select fromNetWork, toNetWork
  useEffect(() => {
    const { chainId: paramChainId } = router.query
    if (paramChainId && allBlockchain?.length > 0) {
      const chain = allBlockchain?.find((item) => item.chainid === +paramChainId)

      setFormValue({
        ...formValue,
        fromNetwork: chain,
        // toNetwork: allBlockchain?.find((item) => item.chainid !== +paramChainId),
      })
    }
  }, [router.query.chainId, formValue.currency, allBlockchain])

  const getMinTokenAmount = async () => {
    const min = await bridgeContract?.getMinTokenAmount(formValue.toNetwork.code)
    setMinTokenAmount(Number(min?.toString() / 10 ** 18))
  }

  useEffect(() => {
    if (minTokenAmount && formValue.sendAmount < minTokenAmount) {
      setFormError((prev) => ({
        ...prev,
        sendAmount: `Please enter amount at least ${+minTokenAmount.toString()}`,
      }))
    }
  }, [minTokenAmount, formValue.sendAmount])

  useEffect(() => {
    if (formValue?.toNetwork?.code) {
      getMinTokenAmount()
    }
  }, [formValue.currency, bridgeContract, formValue.toNetwork?.code])

  const handleMaxSendAmount = () => {
    setFormValue((prev) => ({
      ...prev,
      sendAmount: +currencyBalance,
      receiveAmount: +currencyBalance * 0.95,
    }))
  }

  const [onPresentTransferModal] = useModal(<ModalTransferMultiChain />)

  const handleTransfer = async () => {
    try {
      const { fromNetwork, toNetwork, currency, address, sendAmount, receiveAmount } = formValue

      if (!toNetwork?.chainid) {
        toastError('Error', 'Please select network')
        return
      }

      if (!currency) {
        toastError('Error', 'Please select currency')
        return
      }

      if (!sendAmount) {
        setFormError({
          sendAmount: 'Please enter amount',
        })
        return
      }

      // const minTokenAmount = await bridgeContract.getMinTokenAmount(toNetwork?.code)
      // if (+sendAmount < +minTokenAmount.toString()) {
      //   setFormError({
      //     sendAmount: `Please enter amount at least ${+minTokenAmount.toString()}`,
      //   })
      //   return
      // }

      setFormError({})
      onPresentTransferModal({ ...formValue, chainId, account, native })
    } catch (error) {
      console.log(error)
    }
  }

  const onClosePopupChain = (pChain: any) => {
    if (pChain?.chainid) {
      if (isShowPopup === 'FROM') {
        const chainList = allBlockchain.filter((item) => pChain.blockchainId !== item.chainid)

        setFetchCurrencyAttrParams({
          blockchain_id: pChain?._id,
        })

        switchNetworkAsync(pChain.chainid).then((res) => {
          if (res) {
            setFormValue((prev) => ({
              ...prev,
              fromNetwork: pChain,
              toNetwork: undefined,
              currency: undefined,
            }))
          }
        })

        setFormError({
          ...formError,
          fromNetwork: '',
        })
        setShowPopup(null)
        setFormValue((prev) => ({
          ...prev,
          toNetwork: undefined,
          currency: undefined,
        }))
      } else {
        setFormValue((prev) => ({ ...prev, toNetwork: pChain }))
        setFormError({
          ...formError,
          toNetwork: '',
        })
        setShowPopup(null)
      }
    } else {
      setShowPopup(null)
    }
  }

  useEffect(() => {
    async function getFeeGas() {
      if (bridgeContract) {
        const _gasFee = await bridgeContract?.FEE_NATIVE()
        setGasFee((+_gasFee / 10 ** 18).toString())
      }
    }
    getFeeGas()
  }, [bridgeContract, formValue])

  const onTurnAround = async () => {
    if (formValue.toNetwork) {
      switchNetworkAsync(formValue.toNetwork.chainid).then((res) => {
        if (res) {
          setFormValue((prev) => ({
            ...prev,
            fromNetwork: formValue.toNetwork,
            toNetwork: formValue.fromNetwork,
            currency: undefined,
          }))
          setFetchCurrencyAttrParams({
            blockchain_id: formValue.toNetwork?._id,
          })
        }
      })
    }

    // setFetchCurrencyAttrParams({
    //   blockchain_id: pChain?._id,
    //   currency_id: pChain?.currency_id,
    // })

    // switchNetworkAsync(pChain.chainid).then((res) => {
    //   if (res) {
    //     setFormValue((prev) => ({
    //       ...prev,
    //       fromNetwork: pChain,
    //       toNetwork: undefined,
    //       currency: undefined,
    //     }))
    //   }
    // })
  }

  return (
    <Styles.StyledHome>
      <div style={{ paddingTop: 20 }}>
        <GatewayToGet />
      </div>
      <Styled04>
        <Image src="/images/background/tingx-04.png" alt="bridge" width={299} height={281} />
      </Styled04>
      <Styled03>
        <Image src="/images/background/tingx-03.png" alt="bridge" width={594} height={586} />
      </Styled03>
      <FooterContainer>
        <a href="https://beurl.app/oPmxpikH" target="_blank" rel="noreferrer">
          <TelegramIcon />
        </a>
        <a href="https://beurl.app/NctrvOyM" target="_blank" rel="noreferrer">
          <FacebookIcon />
        </a>
        <a href="https://beurl.app/PcFYxciQ" target="_blank" rel="noreferrer">
          <DiscordIcon />
        </a>
        <a href="https://beurl.app/oqfLfrIO" target="_blank" rel="noreferrer">
          <InstagramIcon />
        </a>
        <a href="https://beurl.app/oqfLfrIO" target="_blank" rel="noreferrer">
          <MIcon />
        </a>
        <a href="https://beurl.app/HTossQMZ" target="_blank" rel="noreferrer">
          <TIcon />
        </a>
        <a href="https://beurl.app/BGIfLDit" target="_blank" rel="noreferrer">
          <LinktreeIcon />
        </a>
        <a href="https://beurl.app/bisIRLHQ" target="_blank" rel="noreferrer">
          <RedditIcon />
        </a>
      </FooterContainer>
    </Styles.StyledHome>
  )
}

export default Home
