const RedditIcon = () => (
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3335 33.7132C26.6384 33.7132 34.1815 26.1701 34.1815 16.8653C34.1815 7.56042 26.6384 0.017334 17.3335 0.017334C8.02867 0.017334 0.485596 7.56042 0.485596 16.8653C0.485596 26.1701 8.02867 33.7132 17.3335 33.7132Z"
      fill="white"
    />
    <path
      d="M27.7185 16.1716C27.7185 14.8419 26.6816 13.7782 25.3855 13.9111C24.8671 13.9111 24.2191 14.1771 23.8302 14.576C22.0157 13.3793 19.9419 12.5814 17.7386 12.5814L18.7754 7.52857L22.1453 8.32639C22.1453 9.25718 22.923 9.92204 23.8302 9.92204C24.7375 9.92204 25.3855 9.12421 25.3855 8.19342C25.3855 7.26263 24.6079 6.59778 23.7006 6.59778C23.0526 6.59778 22.5341 6.99669 22.2749 7.52857L18.6458 6.59778H18.3866C18.257 6.59778 18.257 6.73075 18.257 6.86372L16.9609 12.5814C14.7575 12.5814 12.6838 13.2463 10.8692 14.576C9.96198 13.6452 8.53627 13.6452 7.629 14.576C6.72173 15.5068 6.72173 16.9695 7.629 17.9003C7.75861 18.0332 8.01783 18.2992 8.27705 18.2992V18.964C8.27705 22.5542 12.295 25.4796 17.3497 25.4796C22.4045 25.4796 26.4224 22.5542 26.4224 18.964V18.2992C27.2001 17.9003 27.7185 17.1024 27.7185 16.1716ZM12.1653 17.9003C12.1653 16.9695 12.943 16.3046 13.7207 16.3046C14.6279 16.3046 15.276 17.1024 15.276 17.9003C15.276 18.6981 14.4983 19.4959 13.7207 19.4959C12.8134 19.4959 12.1653 18.831 12.1653 17.9003ZM21.238 22.2883C20.0715 23.0861 18.7754 23.618 17.3497 23.485C15.924 23.485 14.6279 23.0861 13.4614 22.2883C13.3318 22.1553 13.3318 21.8894 13.4614 21.6234C13.591 21.4905 13.8503 21.4905 13.9799 21.6234C14.8871 22.2883 16.0536 22.6872 17.2201 22.5542C18.3866 22.6872 19.5531 22.2883 20.4604 21.6234C20.59 21.4905 20.8492 21.4905 21.1084 21.6234C21.3676 21.7564 21.3676 22.0223 21.238 22.2883ZM20.8492 19.4959C19.9419 19.4959 19.2939 18.6981 19.2939 17.9003C19.2939 17.1024 20.0715 16.3046 20.8492 16.3046C21.7565 16.3046 22.4045 17.1024 22.4045 17.9003C22.5341 18.831 21.7565 19.4959 20.8492 19.4959Z"
      fill="black"
    />
  </svg>
)
export default RedditIcon
