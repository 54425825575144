const DiscordIcon = () => (
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4051 33.7132C26.71 33.7132 34.2531 26.1701 34.2531 16.8653C34.2531 7.56042 26.71 0.017334 17.4051 0.017334C8.10027 0.017334 0.55719 7.56042 0.55719 16.8653C0.55719 26.1701 8.10027 33.7132 17.4051 33.7132Z"
      fill="white"
    />
    <path
      d="M24.3044 10.5322C23.0419 9.94364 21.6751 9.51649 20.2512 9.2697C20.2388 9.2693 20.2263 9.27164 20.2149 9.27656C20.2034 9.28148 20.1931 9.28886 20.1848 9.29817C20.0139 9.61142 19.8146 10.0196 19.6817 10.3328C18.1715 10.105 16.6357 10.105 15.1255 10.3328C14.9926 10.0101 14.7932 9.61142 14.6129 9.29817C14.6034 9.27919 14.5749 9.2697 14.5464 9.2697C13.1226 9.51649 11.7652 9.94364 10.4933 10.5322C10.4838 10.5322 10.4743 10.5416 10.4648 10.5511C7.88293 14.4145 7.17101 18.1734 7.52222 21.8943C7.52222 21.9133 7.53172 21.9323 7.5507 21.9418C9.25929 23.1947 10.9014 23.9541 12.5246 24.4572C12.5531 24.4667 12.5816 24.4572 12.5911 24.4382C12.9707 23.9161 13.3125 23.3656 13.6067 22.7866C13.6257 22.7486 13.6067 22.7106 13.5687 22.7011C13.0277 22.4923 12.5151 22.2455 12.012 21.9607C11.9741 21.9418 11.9741 21.8848 12.0025 21.8563C12.1069 21.7804 12.2114 21.695 12.3158 21.619C12.3348 21.6 12.3632 21.6 12.3822 21.6095C15.6475 23.0998 19.1691 23.0998 22.3965 21.6095C22.4155 21.6 22.4439 21.6 22.4629 21.619C22.5673 21.7045 22.6718 21.7804 22.7762 21.8658C22.8141 21.8943 22.8141 21.9513 22.7667 21.9702C22.2731 22.2645 21.751 22.5018 21.21 22.7106C21.172 22.7201 21.1625 22.7676 21.172 22.7961C21.4757 23.3751 21.8175 23.9256 22.1877 24.4477C22.2161 24.4572 22.2446 24.4667 22.2731 24.4572C23.9057 23.9541 25.5479 23.1947 27.2565 21.9418C27.2755 21.9323 27.285 21.9133 27.285 21.8943C27.7026 17.5943 26.592 13.8639 24.3424 10.5511C24.3329 10.5416 24.3234 10.5322 24.3044 10.5322ZM14.1003 19.6257C13.1226 19.6257 12.3063 18.7239 12.3063 17.6133C12.3063 16.5027 13.1036 15.601 14.1003 15.601C15.1065 15.601 15.9038 16.5122 15.8943 17.6133C15.8943 18.7239 15.097 19.6257 14.1003 19.6257ZM20.7164 19.6257C19.7387 19.6257 18.9223 18.7239 18.9223 17.6133C18.9223 16.5027 19.7197 15.601 20.7164 15.601C21.7225 15.601 22.5199 16.5122 22.5104 17.6133C22.5104 18.7239 21.7225 19.6257 20.7164 19.6257Z"
      fill="black"
    />
  </svg>
)
export default DiscordIcon
